<template>
    <div class="demoniceye__anser">
        <baydin-navbar title="နတ်မျက်စိ ဗေဒင်" />
        <div class="container baydin-bg-img">
            <div style="padding: 20px;">
                <h2 class="text-white">{{ answer }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
import BaydinNavbar from '@/components/reusable/BaydinNavbar.vue';

export default {
    components: {
        'baydin-navbar': BaydinNavbar,
    },

    data() {
        return {
            answer: '',
        };
    },

    mounted() {
        this.getAnswer();
    },

    methods: {
        getAnswer() {
            var formdata = new FormData();
            formdata.append('question', this.$route.params.id);

            var baydin_token = localStorage.getItem('baydin_token');

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${baydin_token}`,
                },
            };

            fetch(
                'https://ibaydin.pyaesoneshin.com/ibaydin/public/api/demoniceye/answer',
                requestOptions,
            )
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    this.answer = result.data.answer.name;
                })
                .catch(error => console.log('error', error));
        },
    },
};
</script>
<style lang="scss">
.baydin-bg-img {
    background-image: url('~@/assets/img/baydin/horoscope.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
}

.pb-20 {
    padding-bottom: 20px;
}
</style>
